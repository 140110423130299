import React from 'react';

// LI, FB, IG, TW
const SOCIAL_MEDIA_LINKS = [
  {
    label: 'LinkedIn',
    src: 'https://subsites.icapps.be/icapps/li-logo',
    url: 'https://www.linkedin.com/company/mrwatts',
  },
  {
    label: 'Facebook',
    src: 'https://subsites.icapps.be/icapps/fb-logo',
    url: 'https://www.facebook.com/mrwatts.io',
  },
];

const EmailTemplate = ({ name, jobFunction, phoneNumber, email, color, templateRef }) => (
  <table cellPadding="0" cellSpacing="0" style={{ minWidth: '200px', border: 'none' }} ref={templateRef}>
    <tbody>
      <tr>
        <td>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td>
                  <p
                    style={{
                      margin: '0px',
                      fontSize: '12px',
                      fontFamily: 'Helvetica, sans-serif',
                    }}
                  >
                    Met vriendelijke groeten, kind regards,
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            cellPadding="0"
            cellSpacing="0"
            style={{
              fontFamily: 'Helvetica, sans-serif',
              marginTop: '16px',
            }}
          >
            <tbody>
              <tr>
                <td style={{ paddingRight: '16px' }}>
                  <img
                    alt=""
                    style={{
                      verticalAlign: 'middle',
                      height: '57px',
                      objectFit: 'contain',
                      margin: '0 0 0 -2px',
                    }}
                    src={`https://mrwatts.io/wp-content/uploads/2020/01/logo_mrwatts_signature_v2_darkblue_2x.png`}
                  />
                </td>
                <td style={{ paddingRight: '32px' }}>
                  {name && (
                    <h1
                      style={{
                        whiteSpace: 'nowrap',
                        wordBreak: 'keep-all',
                        fontFamily: 'Helvetica, sans-serif',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#002548',
                        margin: '0 0 2px 0',
                      }}
                    >
                      {name}
                    </h1>
                  )}
                  {jobFunction && (
                    <h2
                      style={{
                        whiteSpace: 'nowrap',
                        wordBreak: 'keep-all',
                        fontFamily: 'Helvetica, sans-serif',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#C3C9D3',
                        margin: 0,
                      }}
                    >
                      {jobFunction}
                    </h2>
                  )}
                  {(email || phoneNumber) && (
                    <span
                      style={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        wordBreak: 'keep-all',
                        margin: '7px 0 0 0',
                        fontSize: '11px',
                        color: '#009ac7',
                        textDecoration: 'none',
                      }}
                    >
                      {email && (
                        <a
                          style={{
                            display: 'inline',
                            whiteSpace: 'nowrap',
                            wordBreak: 'keep-all',
                            margin: '0 0.4rem 0 0',
                            fontSize: '11px',
                            color: '#009ac7',
                            textDecoration: 'none',
                          }}
                          href={`mailto:${email}`}
                        >
                          {email}
                        </a>
                      )}
                      |
                      {phoneNumber && (
                        <a
                          style={{
                            display: 'inline',
                            whiteSpace: 'nowrap',
                            wordBreak: 'keep-all',
                            margin: '0 0.4rem 0 0.4rem',
                            fontSize: '11px',
                            color: '#009ac7',
                            textDecoration: 'none',
                          }}
                          href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                        >
                          {phoneNumber}
                        </a>
                      )}
                      |
                      <a
                        style={{
                          display: 'inline',
                          whiteSpace: 'nowrap',
                          wordBreak: 'keep-all',
                          margin: '0 0 0 0.4rem',
                          fontSize: '11px',
                          color: '#009ac7',
                          textDecoration: 'none',
                        }}
                        href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                      >
                        www.mrwatts.io
                      </a>
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  {SOCIAL_MEDIA_LINKS.map(social => (
                    <a style={{ marginRight: '10px' }} href={social.url} key={social.label}>
                      <img
                        style={{ width: '20px', backgroundColor: 'transparent' }}
                        src={social.src}
                        alt={social.label}
                      />
                    </a>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

export default EmailTemplate;
