import React, { useState, useRef } from 'react';
import EmailTemplate from './components/emailTemplate/EmailTemplate';
import './app.scss';

const App = () => {
  const [signature, setSignature] = useState({
    name: '',
    jobFunction: '',
    phoneNumber: '',
    email: '',
    color: 'blue',
  });
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const templateRef = useRef(null);

  const handleChange = ({ target: { id, value } }) => {
    setSignature({ ...signature, [id]: value });
    setIsCopiedToClipboard(false);
  };

  const copyToClipboard = () => {
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(templateRef.current);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    setIsCopiedToClipboard(true);
  };

  const renderInputField = (id, label, value, placeholder = '') => (
    <div className="input-field">
      <label htmlFor={id}>{label}</label>
      <input id={id} value={value} placeholder={placeholder} onChange={handleChange} />
    </div>
  );

  return (
    <main className="App">
      <section className="template-input">
        <h1 className="title">Create your Mr.Watts signature</h1>
        {renderInputField('name', 'Name', signature.name)}
        {renderInputField('jobFunction', 'Function', signature.jobFunction)}
        {renderInputField('phoneNumber', 'Phone number', signature.phoneNumber, '+32 123 45 67 89')}
        {renderInputField('email', 'E-mail', signature.email)}
      </section>
      <section className="email-template">
        <EmailTemplate
          name={signature.name}
          jobFunction={signature.jobFunction}
          phoneNumber={signature.phoneNumber}
          email={signature.email}
          color={signature.color}
          templateRef={templateRef}
        />
      </section>
      <div className="copy-wrapper">
        <button className="copy-button" onClick={copyToClipboard}>
          Copy to clipboard
        </button>
        {isCopiedToClipboard && <span className="copy-message">Copied to clipboard</span>}
      </div>
    </main>
  );
};

export default App;
